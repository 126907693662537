import $ from "jquery"
import "select2"
import "select2/dist/js/i18n/ja"

class Select2 {
  static start() {
    const init = (el) => {
      const $el = $(el)

      const options = {
        tag: el.multiple,
        tokenSeparators: [",", " "],
        allowClear: true,
        placeholder: el.multiple || "----",
        width: "100%",
        language: "ja",
      }
      const apiUrl = el.dataset.apiUrl
      if (apiUrl) {
        options["ajax"] = {
          url: apiUrl,
          cache: false,
          delay: 500,
          data: (params) => ({
            q: params.term,
            page: params.page,
          }),
        }
      }

      const value = el.dataset.value
      if (value) {
        const data = JSON.parse(value)
        options["data"] = data
      }

      $el.select2(options)

      if (options["data"]) {
        if (el.multiple) {
          const ids = options["data"].map(({ id }) => id)
          $el.val(ids).trigger("change")
        } else if (options["data"][0]) {
          const id = options["data"][0].id
          $el.val(id).trigger("change")
        }
      }
    }

    const els = document.getElementsByClassName("select2")
    Array.from(els).forEach(init)

    const cocoonEls = document.getElementsByClassName("cocoon")
    Array.from(cocoonEls).forEach((cocoonEl) => {
      $(cocoonEl).on("cocoon:after-insert", (e, contentEl) => {
        const els = contentEl[0].getElementsByClassName("select2")
        Array.from(els).forEach(init)
      })
    })
  }

  static destroy() {
    const els = document.getElementsByClassName("select2")
    Array.from(els).forEach((el) => {
      const $el = $(el)
      if ($el.first().data("select2") !== undefined) {
        $el.select2("destroy")
      }
    })
  }
}

export default Select2